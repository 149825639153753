.App {
 display: flex;
 flex-direction: column;
 align-items: center;
}

.AppTitleContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 100%;
  border-bottom: 1px solid #dadce0;
  box-shadow: 0px 4px 10px 5px #DADCE0;
  color: #313131;
}

.AppHeaderC{
  width: 33.33%;
  height: 100%;
  display: flex;
  align-items: center;
}

.Favicon{
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
}

.Favicon:hover{
  cursor: pointer;
}

.FaviconSpan{
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  color: #313131;
}

.FaviconSpan:hover{
  cursor: pointer;
}

.AppHeaderC2{
  width: 33.33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AppLogoContainer{
  height: 40px;
  width: 40px;
  background-color: #e74c3c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 0px 15px 0px 0px;
}

.AppLogo{
  height: 25px;
}

.AppTitle{
  font-size: 26px;
  color: #313131;
}

.AppSecretContainer{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40vh;
  background-color: #faf6f6;
  border-bottom: 1px solid #dadce0;
}

.AppExplTitle{
  font-size: 20px;
  font-weight: 600;
  color: #313131;
}

.Expl{
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.ExplSmall{
  height: 100px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.ExplSmallNum{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #efefef;
  font-weight: 800;
  color: #e74c3c;
  background-color: white;
}

.ExplSmallTitle{
  width: 340px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #313131;
}

.InputSecret{
  height: 35px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  padding: 6px 12px;
  font-size: 20px;
  font-weight: 700;
  color: white;
  background-color: #e74c3c;
}

.BodyContainer{
  height: 100vh;
  width: 100%;
  display: flex;
}

.BodySmallContainer{
  width: 50%;
  height: 100%;
}

.BodySmallContainerElementContainer{
  height: 10vh;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.BodySmallContainerOrganisator{
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Input{
  height: 35px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  padding: 6px 12px;
  font-size: 20px;
  background-color: #fff;
}

.CompteInput{
  height: 35px;
  width: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  padding: 6px 12px;
  margin-right: 20px;
  font-size: 20px;
  background-color: #fff;
}

.FunctionContainer{
  height: 10vh;
  margin-left: 76px;
  width: 330px;
  display: flex;
  justify-content: space-evenly;
}

.FunctionSmallContainer{
  height: 50px;
  width: 50px;
  background-color: #e74c3c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
}

.FunctionSmallContainerDownLoad{
  height: 50px;
  width: 50px;
  background-color: #e74c3c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
}

.FunctionSmallExpl{
  position: absolute;
  top: 60px;
  display: none;
  height: 25px;
  width: 160px;
  background-color:  #626567 ;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.FunctionSmallContainer:hover{
  cursor: pointer;
}

.FunctionSmallContainer:hover .FunctionSmallExpl{
  display: flex;
  align-items: center;
  justify-content: center;
}

.FunctionSmallContainerDownLoad:hover{
  cursor: pointer;
}

.FunctionSmallContainerDownLoad:hover .FunctionSmallExpl{
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddIcon{
  height: 30px;
  margin: 20px;
}

.AddIcon:hover{
  cursor: pointer;
}

.DownIcon{
  height: 25px;
  margin: 20px;
}

.DownIcon:hover{
  cursor: pointer;
}

.ResultCrypted{
  min-height: 10vh;
  display: flex;
  align-items: center;
  overflow: auto;
  position: relative;
}

.ResultCryptedVisible{
  font-size: 20px;
  font-weight: 600;
  color: #313131;
}

.DecryptOrder{
  width: 80px;
}

.Link{
  text-decoration: none;
}

.CryptedPasswordsContainer{
  display: block;
}

.CryptedPasswordsContainerSmallScreen{
  display: none;
}

.BodySmallContainerDecrypt{
  display: block;
  width: 50%;
  height: 100%;
}

.EyeLogo{
  height: 20px;
}

.EyeLogoContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-left: 5px;
}

.EyeLogoContainer:hover{
  cursor: pointer;
}

@media (max-width: 575.98px){
  .AppHeaderC{
    display: none;
  }

  .AppHeaderC2{
    width: 100%;
  }

  .AppTitle{
    font-size: 18px;
  }

  .AppSecretContainer{
    display: flex;
    justify-content: space-evenly;
    height: 600px;
  }

  .AppExplTitle{
    display: none;
  }

  .Expl{
    width: 100%;
    height: 80%;
    flex-direction: column;
  }

  .ExplSmall{
    width: 100vw;
  }

  .ExplSmallTitle{
    width: 80%;
  }

  .FunctionContainer{
    height: 100px;
    margin-left: 0px;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .InputSecret{
    width: 200px;
  }

  .CryptedPasswordsContainer{
    display: none;
  }


  .CryptedPasswordsContainerSmallScreen{
    display: block;
    height: 100px;
    width: 300px;
  }

  .BodySmallContainerOrganisator{
    display: none;
  }

  .BodySmallContainer{
    width: 100vw;
    height: 100%;
  }

  .BodySmallContainerElementContainer{
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .ResultCryptedVisible{
    font-size: 18px;
    font-weight: 600;
    color: #313131;
  }

  .FunctionSmallContainerDownLoad{
    display: none;
  }

  .SmallScreenResultSpan{
    color:#e74c3c
  }

  .AppTitleContainer{
    height: 100px;
  }

  .BodySmallContainerDecrypt{
    display: none;
    width: 50%;
    height: 100%;
  }

  .CompteInput{
    display: none;
  }

  .EyeLogoContainer{
    display: none;
  }

}